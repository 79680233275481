<template>
	<div class="w-100">
		<div
			class="wrapper-player-awards text-center"
			v-for="(award, ind) in awards"
			:key="`award-${ind}`"
		>
			<div class="player-award">
				<img :src="bg" alt="Fondo del premio" class="img-fluid" />
				<div class="images row">
					<div class="col-2">
						<img
							:src="award.tournament_image"
							:alt="award.tournament_name"
							class="img-fluid"
						/>
					</div>
					<div class="col-6">
						<h5>{{ award.tournament_name }}</h5>
						<h5 v-if="award.reason === 'CHAMPION'">
							{{ award.division_name }}
						</h5>
						<h5>{{ award.season }}</h5>
						<h5>{{ playerFront.nick }}</h5>
						<h5 v-if="award.reason !== 'CHAMPION'">
							{{ award.quantity }}<br />{{ award.reason }}
						</h5>
					</div>
					<div class="col-2" v-if="award.reason === 'CHAMPION'">
						<img
							:src="award.division_image"
							:alt="award.division_name"
							class="img-fluid"
						/>
					</div>
					<div class="col-2" v-else>
						<img :src="award.image" :alt="award.reason" class="img-fluid" />
					</div>
					<div class="col-2">
						<img
							:src="award.custom_image_team"
							:alt="award.team_name"
							class="img-fluid"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			awards: [],
			bg: '',
		}
	},
	computed: {
		...mapGetters(['lang', 'console', 'playerFront']),
	},
	mounted() {
		this.$store.dispatch('SET_MENU_PRIMARY_USER', '')
		this.fetchData()
	},
	methods: {
		fetchData() {
			const lang = this.$route.params.lang
			const console = this.$route.params.console
			const slug = this.$route.params.playerSlug
			const path = `${lang}/console/${console}/player/${slug}/awards`
			this.$axios.get(path).then((response) => {
				this.awards = response.data.awards
				this.bg = response.data.bg
			})
		},
	},
}
</script>
